export default function CommunityService() {
  return (
    <div>
      <h3 id="communityService" name="communityService">
        Community Service/Hospitality
      </h3>
      <p>
        The Community Services/Hospitality focuses on identifying needs in the Community and responding with services based on these specific needs. This
        include giving material aid like gathering and preparing clothing, food and other supplies for those in need and works closely with Personal Ministry,
        the Adventist Men, deacons, deaconesses, and other church departments in community outreach. Other services include hospitality, educational seminars,
        community development, visiting, counseling, and other services relevant to the community.
      </p>
      <h5>Link:</h5>
      <a href="http://adventistontario.org/Directory/CommunityServiceCenters.aspx" rel="noopener noreferrer" target="_blank">
        http://adventistontario.org/Directory/CommunityServiceCenters.aspx
      </a>
      <br />
      <br />
      <h5>Community Services / Hospitality Leader</h5>
      <p>Chioma Isaac</p>
      <a href="mailto:communityservices@torontonigeriansda.org">communityservices@torontonigeriansda.org</a>
    </div>
  );
}
