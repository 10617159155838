export default function WomenMinistry() {
  return (
    <div>
      <h3>Women's Ministry</h3>
      <p>
        The Women’s Ministries Department exists to uphold, encourage, and challenge Seventh-day Adventist Women in their daily walk as disciples of Jesus
        Christ and as members of His world church.
      </p>

      <br />
      <h5>Women Ministry Leader</h5>
      <p>Ijeoma Obike</p>
      <a href="mailto:womenministries@torontonigeriansda.org">womenministries@torontonigeriansda.org</a>
    </div>
  );
}
