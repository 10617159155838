import React, { useEffect, useState } from "react";
import axios from "axios";
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from "@mui/material";

const OfficerTable = () => {
  const [dataTable, setDataTable] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get("https://tnsdac-backend.herokuapp.com/api/officers")
        .then((res) => setDataTable(res.data))
        .catch((err) => console.log(err));
    };
    fetchData();
  }, []);

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 400, maxWidth: 639 }}>
      <Table aria-label="a dense table" size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{ fontWeight: "bold" }}>
              Office
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "bold" }}>
              Officer Name
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "bold" }}>
              Email
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataTable.map((row) => (
            <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" sx={{ fontSize: "small" }} scope="row">
                {row.office}
              </TableCell>
              <TableCell align="center" sx={{ fontSize: "small" }}>
                {row.officerName}
              </TableCell>
              <TableCell align="center" sx={{ fontSize: "small" }}>
                {row.email}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OfficerTable;
