import React from "react";
import "../../Assets/css/Map/Map.css";

const Map = () => {
  return (
    <div className="container">
      <div className="text-center">
        <h3 className="worship-with-us mt-4">Worship with us</h3>
      </div>
      <div className="row mt-4 mb-5">
        <div className="col-lg-5 my-lg-5 mt-lg-5 py-lg-5 ml-sm-5">
          <div className="text-sm-center px-3">
            <h4>Join Us This Saturday!</h4>
            <p>We worship every Saturday at 1 Elrose Avenue, North York, ON.</p>
            <p>We'd love to get to meet with you.</p>
          </div>
        </div>
        <div className="col-sm-12 col-lg-7">
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                title="map"
                style={{ width: "100%", height: "60vh" }}
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2883.6592567190983!2d-79.52562753661127!3d43.71762984683359!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b30e56d3bfb61%3A0x7c5c5ec7963acc5!2s1%20Elrose%20Ave%2C%20North%20York%2C%20ON%20M9M%202H5!5e0!3m2!1sen!2sca!4v1713924407391!5m2!1sen!2sca" 
                width="100%"
                height="600"
                frameborder="0"
              >
                <a href="https://www.maps.ie/distance-area-calculator.html">area maps</a>
              </iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Map;
