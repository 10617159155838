import React from 'react'
import {Link} from 'react-router-dom'
import '../../Assets/css/Event/event.css'

const Events_card = (props) => {
  return (
    <>
      <div className="col-lg-4 col-md-6 col-sm-8">
        <img className='image-width' src={props.imgSrc} alt="" />
      </div>
      <div className="col-lg-8 col-md-6 col-sm-4">
        <p className="h5">{props.title}</p>
        <p>{props.text}</p>
        {/* <Link to={props.link}>Read More</Link> */}
      </div>
      <hr className='mx-2 my-3'/>

    </>
  )
}

export default Events_card