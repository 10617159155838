import React from 'react'
import "../../Assets/css/Admin/Admin.css"
import "../../Assets/css/styles.css"


const AdminLogin = () => {
  return (
    <>
        <div className="text-center py-5 login-form">
    
    <main className="form-signin w-100 m-auto">
      <form>
        
        <h1 className="h3 mb-3 fw-normal">Admin Login</h1>
    
        <div className="form-floating">
          <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
          <label for="floatingInput">Email address</label>
        </div>
        <div className="form-floating">
          <input type="password" className="form-control" id="floatingPassword" placeholder="Password" />
          <label for="floatingPassword">Password</label>
        </div>
        <button className="w-100 btn btn-lg btn-primary" type="submit">Login</button>

        <div className="checkbox mb-3 mt-3">
          <a href="" className='text-dark'>Forgot password?</a>
        </div>
      </form>
    </main>
    
        
      </div>
    </>
  )
}

export default AdminLogin