export default function AudioVisual() {
  return (
    <div>
      <h3>Audio/Visual</h3>
      <p>
        The Audio/Visual Ministry is responsible for setup, maintenance, coordination, and use of the church's audio, visual, and lighting technology used
        during services and for other special on-and-off site events. The ministry's mission is to glorify God by ensuring that His Word and our praise and song
        to Him is seen and heard clearly and without distraction or interference within our local church and throughout the world as it's broadcasted or
        distributed on media with the highest video and audio quality available.
      </p>
      <p>
        The Audio/Visual Team works closely with the worship leaders, Elders and Pastor to create powerful, dynamic and meaningful worship experiences for
        today's church.
      </p>

      <br />
      <h5>Audio-Visual Ministry Leader</h5>
      <p>Okezie Oluikpe</p>
      <a href="mailto:audiovisual@torontonigeriansda.org">audiovisual@torontonigeriansda.org</a>
    </div>
  );
}
