import React from "react";
import "../../Assets/css/Header/Header.css";

const Header = () => {
  return (
    <>
      <header className="main-header">
        <div className="header-content" data-aos="fade-up" data-aos-duration="2500">
          <h1 className="intro-text">
            Toronto Nigerian
            <br />
            Seventh-Day Adventist Church
          </h1>
          <p className="mb-4">The right place to worship</p>
          <a className="btn watch-sermon" href="https://www.youtube.com/@torontonigeriansda7396/streams" rel="noopener noreferrer" target="_blank">
            Watch our sermons
          </a>
        </div>
      </header>
    </>
  );
};

export default Header;
