/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import axios from "axios";
import { Formik, ErrorMessage, Field } from "formik";
import { Alert } from "react-bootstrap";

//import 'bootstrap/dist/css/bootstrap.css'

import "../Assets/css/Button.css";
import "../Assets/css/Forms.css";
import formSchema from "../Components/Validation/formValidation";

function bibleStudyRequest() {
  const initialValues = {
    fullName: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    const data = {
      fullName: values.fullName,
      email: values.email,
      phone: values.phone,
      subject: values.subject,
      message: values.message,
    };

    const axiosConfig = {
      headers: {
        "Content-type": "application/json",
      },
    };
    try {
      await axios
        .post("https://tnsdac-backend.herokuapp.com/api/bibleStudyRequests", data, axiosConfig)
        .then(() => {
          showAlert();
        })
        .then(resetForm({ values: "" }));
    } catch (error) {
      // console.log(error, "bad error");
      showError();
    }
  };

  const [errorMessage, showErrorMessage] = useState("none");
  const [display, setDisplay] = useState("none");

  function showAlert() {
    setDisplay("block");
  }

  function closeAlert() {
    setDisplay("none");
  }

  function showError() {
    showErrorMessage("block");
  }

  function closeError() {
    showErrorMessage("none");
  }

  return (
    <div className="form_style page-bg">
      <section>
        <div className="">
          <Alert variant="success" onClose={() => closeAlert()} style={{ display: display }} dismissible>
            Message sent successfully.
          </Alert>
          <Alert variant="danger" onClose={() => closeError()} style={{ display: errorMessage }} dismissible>
            Message not sent. Please try again.
          </Alert>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="request-form">
                <div className="intro pt-4">
                  <h2>Bible Studies Request Form</h2>
                  <h4>Want to develop your Bible knowledge</h4>
                  <p>Send us a message below! We’ll get back so you as soon as possible</p>
                </div>
                <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={handleSubmit}>
                  {({ handleSubmit }) => (
                    <>
                      <form onSubmit={handleSubmit}>
                        <div className="form_contact_info">
                          <div className="form-group">
                            <label htmlFor="">Full Name: *</label>
                            <Field className="form-control" name="fullName" autoComplete="given-name" type="text" />
                            <p style={{ color: "red" }}>
                              <ErrorMessage name="fullName" />
                            </p>
                          </div>

                          <div className="form-group">
                            <label htmlFor="">Email: *</label>
                            <Field type="email" className="form-control" name="email" />
                            <p style={{ color: "red" }}>
                              <ErrorMessage name="email" />
                            </p>
                          </div>

                          <div className="form-group">
                            <label htmlFor="">Phone Number:</label>
                            <Field type="tel" className="form-control" name="phone" />
                            <p style={{ color: "red" }}>
                              <ErrorMessage name="phone" />
                            </p>
                          </div>
                        </div>

                        <div className="form-group">
                          <label htmlFor="">Subject: *</label>
                          <Field type="text" className="form-control" name="subject" />
                          <p style={{ color: "red" }}>
                            <ErrorMessage name="subject" />
                          </p>
                        </div>

                        <div className="form-group">
                          <label htmlFor="">Message: *</label>{" "}
                          <Field component="textarea" rows="60" className="form-control" name="message" type="text" placeholder="Enter Bible Study Request" />
                          <p style={{ color: "red" }}>
                            <ErrorMessage name="message" />
                          </p>
                        </div>

                        <button type="submit" className="mainButton">
                          Send Message
                        </button>
                      </form>
                    </>
                  )}
                </Formik>
              </div>
            </div>
            <div className="bible-study col-sm-12 col-md-12 col-lg-6 mt-sm-4">
              <h4>Bible Studies</h4>
              <div className="bible-study-links">
                <p>
                  <a href="https://www.bibleinfo.com/en/questions">Common questions</a>{" "}
                </p>
                <p>
                  <a href="https://www.bibleinfo.com/en/topics">Bible Topics</a>
                </p>
                <p>
                  <a href="https://questions.bibleinfo.com/ask">Ask a Bible question</a>
                </p>
                <p>
                  <a href="discoveronline.org">Discover Online Bible Lessons</a>
                </p>
                <p>
                  <a href="https://amazingbiblestudies.com/">Bible University</a>
                </p>
              </div>

              <div className="bible-study-links">
                <p>See below for additional resources:</p>
                <p>
                  <a href="https://www.amazingfacts.org/">Amazing Facts</a>
                </p>
                <p>
                  <a href=">https://www.nadadventist.org/bible-study">SDA Bible Study Resources</a>
                </p>
                <p>
                  <a href="https://www.kingjamesbibleonline.org/">King James Bible</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default bibleStudyRequest;
