import React, { useState } from "react";
import Map from "../Components/Map/Map";
import Header from "../Components/Header/Header";
import "../Assets/css/Home/Home.css";
import { Link } from "react-router-dom";
import NewChurchLocationPopup from "../Components/NewChurchLocation/NewChurchLocationPopup";

const Home = () => {
  return (
    <div>
      {/* <NewChurchLocationPopup /> */}
      <main id="mainHomePage">
        <div>
          <Header />
          <div className="welcome my-5">
            <h2 className="mx-5 welcome-heading">Welcome to Toronto Nigerian Seventh-Day Adventist Church</h2>
            <p className="mx-5 welcome-text">
              We are a bible-believing church, equipped with the word and having a mission to proclaim the gospel to the world. As a church we preach salvation,
              faith in Christ and total devotion to God.
            </p>
            <Link to="/about" className="mainButton">
              More about us
            </Link>
          </div>
          <section className="mission-belief">
            <div className="">
              <div className="row">
                <div className="mission col-sm">
                  <h3 className="mission-heading my-5 mx-5">Our Mission</h3>
                  <p className="mission-text mx-5">
                    Our mission is to Foster the proclamation of the Gospel in this generation till Christ comes while maintaining our cultural heritage.
                  </p>
                </div>
                <div className="belief col-sm pb-4">
                  <h3 className="belief-heading my-5 mx-5 text-white">Our Beliefs</h3>
                  <p className="belief-text mx-5">
                    Seventh-day Adventists accept the Bible as their only creed and hold certain fundamental beliefs to be the teaching of the Holy Scriptures.
                    These beliefs, as set forth here, constitute the church's understanding and expression of the teaching of Scripture.
                  </p>
                  <a
                    href="https://www.adventist.org/beliefs/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="mainButton bg-white mx-5 text-dark mb-4 mb-sm-4"
                  >
                    More on Beliefs
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section className="worship-days">
            <div className="text-center text-white mt-5">
              <h3 className="worship-days-heading worship-days-section">Our worship days</h3>
            </div>
            <div className="worship-days-text mx-5 text-white">
              <p>
                We worship every Saturday, in-person or online. We also have our mid-week bible study on Thursdays. Join us for our next service, we look
                forward to fellowshipping with you!
              </p>
            </div>
            <div className="worship-days-end container my-5">
              <div className="row">
                <div className="col-sm">
                  <i className="text-white fa-solid fa-3x fa-church"></i>
                  <h3 className="mt-3">Saturday | 9: 30 am</h3>
                  <h6 className="mt-3 text-white">Sabbath worship</h6>
                  <p>Worship with us this saturday, online or in-person.</p>
                  <a className="btn worship-online-btn mb-5" rel="noopener noreferrer" target="_blank" href="https://us04web.zoom.us/j/989447218">
                    Online link
                  </a>
                </div>
                <div className="col-sm">
                  <i className="text-white fa-solid fa-3x fa-hands-praying"></i>
                  <h3 className="pt-3">Thursday | 6:45 pm</h3>
                  <h6 className="mt-3 text-white">Prayer Meeting</h6>
                  <p>Join us for weekly prayer meetings.</p>
                  <a className="btn worship-online-btn" rel="noopener noreferrer" target="_blank" href="https://us02web.zoom.us/j/988244054">
                    Online link
                  </a>
                </div>
              </div>
            </div>
          </section>
          <Map />
        </div>
      </main>
    </div>
  );
};

export default Home;
