export default function YouthMinistry() {
  return (
    <div>
      <h3>Youth Ministry</h3>
      <p>
        Under the AYM, youth are to work together, in cooperation with the wider church community, towards the development of a strong youth ministry that
        includes spiritual, mental, and physical development of each individual, Christian social interaction, and an active witnessing program that supports
        the general soul-winning plans of the church. The goal of AYM should be to involve all youth in activities that will lead them to active church
        membership and train them for Christian service.
      </p>
      <h5>Link:</h5>
      <a href="https://www.nadadventist.org/departments/youth-young-adult-ministries ">https://www.nadadventist.org/departments/youth-young-adult-ministries</a>
      <br /><br />
      <h5>Youth Ministry Leader</h5>
      <p>Ife Ajayi</p>
      <a href="mailto:youthministries@torontonigeriansda.org">sabbathschool@torontonigeriansda.org</a>
    </div>
  );
}
