import React from 'react';
import { Link } from "react-router-dom"

const PageNotFound = () => {
  return (
    <div className='page-not-found'>
        <div>404 | Page Not Found</div>
        <p>Back to <Link to="/">Home</Link></p>
    </div>
  )
}

export default PageNotFound