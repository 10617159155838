export default function ChildrenMinistry() {
  return (
    <div>
      <h3>Children's Ministries</h3>
      <p>
        The Children’s Ministries Department exists to develop the faith of children from birth through age fourteen, leading them into a loving, serving
        relationship with Jesus.
      </p>

      <br />
      <h5>Children Ministry Leader</h5>
      <p>Lisa Ahunanya</p>
      <a href="mailto:childrenministries@torontonigeriansda.org">stewardship@torontonigeriansda.org</a>
    </div>
  );
}
