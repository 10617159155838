export default function Deacons() {
  return (
    <div>
      <h3>Deacons and Deaconesses</h3>
      <p>
        Deacons are concerned with both the material and spiritual needs of the congregation, including the comfort of worshippers, security, and upkeep of the
        church property. They minister to the sick and shut-in and have special responsibilities during the Communion and Baptismal services.
      </p>

      <br />
      <h5>Head Deacon</h5>
      <p>Diran Akinrinade</p>
      <h5>Ass. Head Deacon</h5>
      <p>Rudolph Lewis</p>
      <h5>Head Deaconess</h5>
      <p>Rachel Fasanu</p>
      <a href="mailto:deacons@torontonigeriansda.org">deacons@torontonigeriansda.org</a>
    </div>
  );
}
