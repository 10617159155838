import React, { useState, useEffect } from "react";

import { Bulletin } from "../Components/Events/bulletinData";
import { Newsletter } from "../Components/Events/newsletterData";

const Archive = () => {
  const [thenewsletter, setNewsletter] = useState([]);
  const [thebulletin, setBulletin] = useState([]);
  useEffect(() => {
    setNewsletter(Newsletter.reverse());
    setBulletin(Bulletin.reverse());
  }, []);

  return (
    <div className="container">
      <h1>Archive</h1>
      <div className="row justify-content-center">
        <div className="col-3">
          <h2>Newsletter</h2>
          {thenewsletter.map((item) => (
            <li className="thekey" key={item.id}>
              <a className="thekey" href={item.docSrc} target="_blank">
                {item.name}
              </a>
            </li>
          ))}
        </div>
        <div className=" col-3">
          <h2>Bulletin</h2>
          {thebulletin.map((item) => (
            <li className="thekey" key={item.id}>
              <a className="thekey" href={item.docSrc} target="_blank">
                {item.name}
              </a>
            </li>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Archive;
