import React, { useState } from "react";
import axios from "axios";
import { Formik, ErrorMessage, Field } from "formik";
import "../Assets/css/Contact/Contact.css";

import "../Assets/css/Button.css";
import "../Assets/css/Forms.css";
import formSchema from "../Components/Validation/formValidation";
import { Alert } from "react-bootstrap";

const Contact = () => {
  const initialValues = {
    fullName: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    const data = {
      fullName: values.fullName,
      email: values.email,
      phone: values.phone,
      subject: values.subject,
      message: values.message,
    };

    const axiosConfig = {
      headers: {
        "Content-type": "application/json",
      },
    };
    try {
      await axios
        .post("/api/contactUs", data, axiosConfig)
        .then(() => {
          showAlert();
        })
        .then(resetForm({ values: "" }));
    } catch (error) {
      // console.log(error, "bad error");
      showError();
    }
  };

  const [errorMessage, showErrorMessage] = useState("none");
  const [display, setDisplay] = useState("none");

  function showAlert() {
    setDisplay("block");
  }

  function closeAlert() {
    setDisplay("none");
  }

  function showError() {
    showErrorMessage("block");
  }

  function closeError() {
    showErrorMessage("none");
  }

  return (
    <div className="form_style page-bg">
      <section>
        <div className="">
          <Alert variant="success" onClose={() => closeAlert()} style={{ display: display }} dismissible>
            Message sent successfully.
          </Alert>
          <Alert variant="danger" onClose={() => closeError()} style={{ display: errorMessage }} dismissible>
            Message not sent. Please try again.
          </Alert>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4 contact-form">
              <h1 className="intro pt-3">Contact Us</h1>
              <div className="intro pt-3">
                <h4>HAVE ANY QUESTIONS?</h4>
                <p>Send us a message below! We’ll get back so you as soon as possible.</p>
              </div>
              {/* <OfficerTable /> */}
              <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={handleSubmit}>
                {({ handleSubmit }) => (
                  <>
                    <form onSubmit={handleSubmit}>
                      <div className="form_contact_info">
                        <div className="form-group">
                          <label htmlFor="">Full Name: *</label>
                          <Field className="form-control" name="fullName" autoComplete="given-name" type="text" />
                          <p style={{ color: "red" }}>
                            <ErrorMessage name="fullName" />
                          </p>
                        </div>

                        <div className="form-group">
                          <label htmlFor="">Email: *</label>
                          <Field type="email" className="form-control" name="email" />
                          <p style={{ color: "red" }}>
                            <ErrorMessage name="email" />
                          </p>
                        </div>

                        <div className="form-group">
                          <label htmlFor="">Phone Number:</label>
                          <Field type="tel" className="form-control" name="phone" />
                          <p style={{ color: "red" }}>
                            <ErrorMessage name="phone" />
                          </p>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="">Subject: *</label>
                        <Field type="text" className="form-control" name="subject" />
                        <p style={{ color: "red" }}>
                          <ErrorMessage name="subject" />
                        </p>
                      </div>

                      <div className="form-group">
                        <label htmlFor="">Message: *</label>{" "}
                        <Field component="textarea" rows="60" className="form-control" name="message" type="text" placeholder="Enter Message" />
                        <p style={{ color: "red" }}>
                          <ErrorMessage name="message" />
                        </p>
                      </div>

                      <button type="submit" className="mainButton">
                        Send Message
                      </button>
                    </form>
                  </>
                )}
              </Formik>
            </div>

            <div className="contact-us col-sm-12 col-md-12 col-lg-6 mt-sm-4">
              <h2>Get in Touch</h2>
              <p>
                Phone: <br />
                <a href="tel">416-319-2740 </a>
                <br /> <a href="tel">647-722-1108</a>{" "}
              </p>
              <p>
                Email: <a href="email">info@torontonigeriansda.org</a>
              </p>
              <br />
              <h5>Meetings</h5>
              <p>We meet every Sabbath both in-person and online via Zoom.</p>

              <div className="gmap_canvas">
                <iframe
                  title="map"
                  style={{ width: "100%", height: "60vh" }}
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2883.6592567190983!2d-79.52562753661127!3d43.71762984683359!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b30e56d3bfb61%3A0x7c5c5ec7963acc5!2s1%20Elrose%20Ave%2C%20North%20York%2C%20ON%20M9M%202H5!5e0!3m2!1sen!2sca!4v1713924407391!5m2!1sen!2sca" 
                  width="100%"
                  height="600"
                  frameborder="0"
                >
                  <a href="https://www.maps.ie/distance-area-calculator.html">area maps</a>
                </iframe>
                <p>
                  1 Elrose Avenue, <br /> North York, M9M 2H5, <br /> ON, Canada
                </p>
                <p>
                  Meeting ID: <a href="https://us04web.zoom.us/j/989447218">989 447 218</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className='container text-center mt-5'>
        <div className='container text-center mt-5'>
        <h3>Officers</h3>
          <OfficerTable />
        </div>
      </section> */}
    </div>
  );
};

export default Contact;
