import React from "react";
import "../../Assets/css/Footer/Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section className="footer-section pt-3">
      <section className="footers pb-3">
        <div className="container pt-1">
          <div className="row">
            <div className="col-xs-12 col-sm-7 col-md-4">
              <div className="footers-info mt-3 mx-1">
                <p>
                  <i className="fa-solid fa-2x fa-location-dot"></i>
                  <span className="footer-fa">
                    <a
                      className="external-link footer-link"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.google.com/maps/dir//1+Elrose+Ave,+North+York,+ON+M9M+2H5/@43.7176298,-79.5256275,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x882b30e56d3bfb61:0x7c5c5ec7963acc5!2m2!1d-79.5252596!2d43.7177147!3e0?entry=ttu"
                    >
                      1 Elrose Avenue, North York, ON
                    </a>
                  </span>
                </p>
                <p>
                  <i className="fa fa-2x fa-phone"></i>
                  <span className="footer-fa">
                    <a className="external-link footer-link" href="tel:4163192740">
                      +1 (416)-319-2740
                    </a>
                  </span>
                </p>
                <p>
                  <i className="fa fa-2x fa-envelope"></i>&nbsp;
                  <span className="footer-fa">
                    <a className="external-link footer-link" href="mailto:info@torontonigeriansda.org">
                      info@torontonigeriansda.org
                    </a>
                  </span>
                </p>
              </div>
              <div className="social-icons">
                <a href="https://www.facebook.com/TorontoNigerianSDA/" rel="noopener noreferrer" target="_blank">
                  <i className="fa-brands social fa-2x fa-facebook"></i>
                </a>
                <a href="https://www.youtube.com/channel/UCcHlkRzOGG9dwEPneDzfhTg/videos" rel="noopener noreferrer" target="_blank">
                  <i className="fa-brands fa-2x fa-youtube social"></i>
                </a>
                <a href="mailto:info@torontonigeriansda.org">
                  <i className="fa fa-envelope-square fa-2x social"></i>
                </a>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-2">
              <h5 className="footer-title">About </h5>
              <ul className="list-unstyled">
                <li>
                  <Link to="/about" className="footer-link">
                    Doctrine
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="footer-link">
                    Belief
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="footer-link">
                    Faith
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="footer-link">
                    Service
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-2 footers-three">
              <h5 className="footer-title">Ministries </h5>
              <ul className="list-unstyled">
                <li>
                  <Link to="/prayer-request" className="footer-link">
                    Prayer Request
                  </Link>
                </li>
                <li>
                  <Link to="/bible-study-request" className="footer-link">
                    Bible Study Request
                  </Link>
                </li>
                <li>
                  <Link to="/events" className="footer-link">
                    Events
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-2 footers-four">
              <h5 className="footer-title">Media</h5>
              <ul className="list-unstyled">
                <li>
                  <Link to="/media" className="footer-link">
                    Pictures
                  </Link>
                </li>
                <li>
                  <a href="/images/bulletin_june_22.pdf" rel="noopener noreferrer" target="_blank">
                    Bulletin
                  </a>
                </li>
                <li>
                  <Link to="/events" className="footer-link">
                    Newsletter
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-2">
              <h5 className="footer-title">Policies </h5>
              <ul className="list-unstyled">
                <li>
                  <Link to="/terms-of-use" className="footer-link">
                    Terms Of Use
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-statement" className="footer-link">
                    Privacy Statement
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="footer-link">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="copyright border">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12 pt-3">
              <p className="text-white">© {new Date().getFullYear()} Toronto Nigerian Seventh-Day Adventist Church</p>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Footer;
