import React from 'react'
import PhotoGallery from './PhotoGallery'
const Media = () => {
  return (
    <div>
      <h1 className='content'>Media</h1>
    </div>
  )
}

export default Media
