import React from 'react';
import "../../Assets/css/styles.css";
import OverviewCard from "../../Components/Admin/props/Card-Overview"
import OfficerTable from '../../Components/Table/OfficerTable'

function AdminHome() {
  return (
      <>
      <div className="row">
        <div className="col-3 col-lg-2 d-none d-sm-block d-md-block w-10">
            <ul data-aos="fade-right" className="adminhome-left">
                <li className="left-list"><a href="#members">Members</a></li>
                <li className="left-list"><a href="#events">Events</a></li><hr />
                <li className="left-list"><a href="#bulletin">Bulletin</a></li>
                <li className="left-list"><a href="#announcement">Announcements</a></li>
                <li className="left-list"><a href="#newsletter">Newsletter</a></li><hr />
                <li className="left-list"><a href="#officers">Officers</a></li>
            </ul>
        </div>

        <div className="admin-home col-12 col-sm-9 col-md-9 col-lg-10 w-92">
          <div className="admin-details">
          <div className='welcome-admin py-2'>
            <span className='px-3'>Welcome</span>
            <span className='px-3'><a href="" className='btn btn-dark'>Logout</a></span>
          </div>
        </div>

        <div className='pt-5 h5'>
          Dashboard
        </div>

        <div className="overview">
          <div className='row'>
              <OverviewCard Title="Members" Icon="fa-solid fa-2x fa-people-line overview-icon" Number="100" />
              <OverviewCard Title="Officers" Icon="fa-solid fa-2x fa-people-roof overview-icon" Number="20" />
              <OverviewCard Title="Prayer Requests" Icon="fa-solid fa-2x fa-hands-praying overview-icon" Number="7" />
              <OverviewCard Title="Study Requests" Icon="fa-solid fa-2x fa-book-bible overview-icon" Number="5" />
          </div>
        </div>

        <section className='my-4 mx-2'>
        <div className="row">
          <div className="events col-8 mx-2">
            <p className='h4'>Events</p>
            <table>
              <tr>
                <th>Title</th>
                <th>Date</th>
                <th>Time</th>
              </tr>
              <tr>
                <td>Afro-Carib Cuisine Service</td>
                <td>May 28th, 2022</td>
                <td>3pm</td>
              </tr>
              <tr>
                <td>Choir day</td>
                <td>July 11th, 2022</td>
                <td>3pm</td>
              </tr>
            </table>
            {/* <iframe src="https://calendar.google.com/calendar/embed?height=300&wkst=1&bgcolor=%23ffffff&ctz=America%2FToronto&src=YW5kcmV3aWdiZWlua3V0dUBnbWFpbC5jb20&src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%237986CB&color=%2333B679" style={{ width:"400", height:"300", frameBorder:"0"}}/> */}
          </div>
          <div className="mx-3 my-1 bulletin col-4">
            <p className="h4">Bulletin</p>
            <ul>
              <li>28th May, 2022</li>
              <li>4th June, 2022</li>
              <li>11th June, 2022</li>
              <li>18th June, 2022</li>
            </ul>
            <button>Upload bulletin</button>
          </div>
        </div>
      </section>

      <section className='my-3 mx-2'>
        <div className="row">
          <div className="newsletter col-4 mx-2">
            <p className='h5'>Newsletter</p>
            <ul>
              <li>1st Quarter</li>
              <li>2nd Quarter</li>
            </ul>
          </div>
          <div className="officers col-8 mx-5">
            <p className="h5">Officers</p>
            <OfficerTable/>
          </div>
        </div>
      </section>
      </div>
    </div>
    
    </>
  )
}

export default AdminHome;
