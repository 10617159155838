export default function Stewardship() {
  return (
    <div>
      <h3>Stewardship</h3>
      <p>
        Stewardship ministries encourages members to respond to God’s grace by dedicating all they have to Him. Stewardship responsibility involves more than
        just money. It includes, but is not limited to, the proper care and use of the body, mind, time, abilities, spiritual gifts, relationships, influence,
        language, the environment, and material possessions. The department assists members in their partnership with God in completing His mission through the
        proper utilization of all of His gifts and resources.
      </p>
      <h5>Link</h5>
      <a href="https://www.nadadventist.org/departments/stewardship">https://www.nadadventist.org/departments/stewardship</a>
      <br /><br />
      <h5>Stewardship Leader</h5>
      <p>Elder Okezie Oluikpe</p>
      <a href="mailto:stewardship@torontonigeriansda.org">stewardship@torontonigeriansda.org</a>
    </div>
  );
}
