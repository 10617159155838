import React, { useState, useEffect } from "react";
import { Bulletin } from "../Components/Events/bulletinData";
import EventCard from "../Components/props/Events_card";
import Calendar from "../Components/Events/Calendar";
import { Newsletter } from "../Components/Events/newsletterData";
import "../Assets/css/Event/event.css";
import { Link } from "react-router-dom";
import AgendaCalendar from "../Components/Events/AgendaCalendar";

const Events = () => {
  const [thenewsletter, setNewsletter] = useState([]);
  const [theBulletin, setBulletin] = useState([]);
  useEffect(() => {
    setNewsletter(Newsletter.slice(-4).reverse());
    setBulletin(Bulletin.slice(-4).reverse());
  }, []);

  return (
    <div className="events-page">
      <article className="row">
        <div className="main_events col-lg-6 col-sm-12 col-md-12">
          <section className="upcoming">
            <p className="h5 px-3 py-3">Upcoming Events</p>
            <div className="row px-3">
              <EventCard
                imgSrc="/images/choir-concert.jpg"
                title="Choir concert"
                text="We organized a choir concert on the 9th of July, 2022."
                // link="/events/community-service"
              />
            </div>
          </section>
          <section className="past">
            <p className="h5 px-3 py-3">Past Events</p>
            <div className="row px-3">
              <EventCard
                imgSrc="/images/community-1.jpg"
                title="Community Service"
                text="We play our part to provide services to our neighbouring community."
                // link="/events/community-service"
              />
              <EventCard
                imgSrc="/images/prison.jpg"
                title="Prison Ministry"
                text="We minister to those confined in prisons, spreading the love of God."
                // link="/events/community-service"
              />
              <EventCard
                imgSrc="/images/food-drive.jpg"
                title="Food Drive"
                text="It is always good to give. During the peak of the covid pandemic, we had a food drive."
                // link="/events/community-service"
              />
              <EventCard
                imgSrc="/images/bereaved.jpg"
                title="Visiting the bereaved"
                text="As Christians, we comfort the bereaved and hope for eternity."
                // link="/events/community-service"
              />
            </div>
          </section>
        </div>
        <div className="col-lg-6 col-sm-12 col-md-12">
          <AgendaCalendar />
          <div className="newsletter-bulletin mx-lg-5">
            <div className="newsletter">
              <h4>Newsletters</h4>
              {thenewsletter.map((item) => (
                <li className="thekey" key={item.id}>
                  <a className="thekey" href={item.docSrc} target="_blank">
                    {item.name}
                  </a>
                </li>
              ))}
            </div>

            <div className="bulletin">
              <h4>Bulletin</h4>
              {theBulletin.map((item) => (
                <li className="thekey" key={item.id}>
                  <a className="thekey" href="/images/bulletin_june_22.pdf" rel="noopener noreferrer" target="_blank">
                    Bulletin - June 22nd, 2024
                  </a>
                </li>
              ))}
            </div>
            {/* <div className="archive">
              <Link target="_blank" to="/archive">
                <h6>Click here for the archived newsletter and bulletin</h6>
              </Link>
            </div> */}
          </div>
        </div>
      </article>
    </div>
  );
};

export default Events;
