import React from "react";
import "../Assets/css/Vision_2025/Vision_2025.css";

const Vision_2025 = () => {
  return (
    <>
      <div className="p-5 text-center church-background">
        <div className="mask">
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-white">
              <p className="display-3 fw-bold" style={{ marginTop: "22%" }}>
                VISION 2025
              </p>
              {/* <button className="btn btn-rounded btn-lg mt-5" style={{backgroundColor: "#161E54", color: "white"}}></button> */}
            </div>
          </div>
        </div>
      </div>

      <section className="background">
        <div className="container">
        <p className="h2 green-underline my-3">Background</p>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <p className="tnsda-intro">
                The Toronto Nigerian Seventh-Day Adventist church (TNSDAC) is a growing community-based church congregation in Toronto, Canada that seeks to
                proclaim the gospel of Christ in this generation while maintaining our cultural heritage. We exist to have a Christ focused fellowship for all
                nations that not only glorifies our heavenly Father, but also educates and empowers our members for service that positively impacts the
                community.
              </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-8">
              <img src="/images/Vision_2025.png" className="envelop-img" alt="church" />
              <br />
              <a href="/images/Vision_2025.png" download className="envelop-btn my-2 btn text-light">
                Download
              </a>
            </div>
          </div>
        </div>
        <div className="container">
          <p className="mt-3">Over our 9 years of existence, we have attained several milestone achievements including:</p>
          <ul className="vision-links">
            <li>We grew from a fellowship, to a company and became an organized church in 2018</li>
            <li>We currently have a vibrant membership of 75 baptized members.</li>
            <li>Through our several community-based programs we have impacted the Greater Toronto community via activities such as</li>
            <ul className="vision-links">
              <li>Youth and Young Adult Mentorship; </li>
              <li>Pathfinder youth program – (recently came 1st in the North American Division Seventh Day Adventist Pathfinder Bible Experience);</li>
              <li>Assisting New Immigrants Program;</li>
              <li>Women, Men and Youth Ministries;</li>
              <li>Digital empowerment for elderly (COVID relief initiative to assist the elderly with online worship);</li>
              <li>Food drive;</li>
              <li>Bereavement support for widows and widowers;</li>
              <li>Family visitation and prayer;</li>
              <li>Shelter and Prison Ministry; to name a few.</li>
            </ul>
          </ul>
          <p className="more-text">
            While we have made several great strides, we still have a lot to accomplish. We are currently requesting your financial support to raise the sum of
            $1M towards the down payment for our own place of worship. Our collective vision as a congregation is to have our own place by 2025 which inspired
            VISION-2025.{" "}
          </p>
          <p className="more-text">
            As a registered charity, you can be rest assured that all donations will go specifically to the cause. Similarly, we are able to provide donor
            receipts as requested. Please send your gifts and donations using any of the options below with the tag “VISION 2025”.{" "}
          </p>

          <article className="payment-channels mb-5">
            <div className="my-5 text-center">
              <h3 className="mb-5">How to Donate</h3>
              <div className="row">
                <div className="col-sm">
                  <i className="fa-solid fa-3x fa-money-check-dollar"></i>
                  <h6 className="mt-3">Cash/Cheque</h6>
                  <p className="px-3">Send us a check or cash in the building fund envelope provided</p>
                </div>
                <div className="col-sm">
                  <i className="fa-solid fa-3x fa-credit-card"></i>
                  <h6 className="mt-3">Online</h6>
                  <p className="px-3">
                    Make Donations online <br />
                    <a
                      rel="noopener noreferrer"
                      href="https://adventistgiving.ca/#/org/AN6MGF/envelope/start"
                      className="envelop-btn px-3 text-white"
                      target="_blank"
                    >
                      Link
                    </a>
                  </p>
                </div>
                <div className="col-sm">
                  <img className="w-25" src="/images/interac.png" alt="interac logo" />
                  <h6 className="mt-3">Interac</h6>
                  <p className="px-3">
                    Interac e-transfer to the email: <u>tnigeriantreasurer@adventisiontario.org</u>
                  </p>
                </div>
              </div>
            </div>
          </article>

          <article className="bible text-center">
            <p className="bible-text">
              As Ephesians 2 : 19-22 says; <br />
              <em>
                “Consequently, you are no longer foreigners and strangers, but fellow citizens with God’s people and also members of his household, built on the
                foundation of the apostles and prophets, with Christ Jesus himself as the chief cornerstone. In him the whole building is joined together and
                rises to become a holy temple in the Lord. And in him you too are being built together to become a dwelling in which God lives by his Spirit”.
              </em>
            </p>
          </article>

          <p className="mt-3 pb-3">Join us as we make the LORD a sanctuary, that He might dwell amongst us.</p>
        </div>
      </section>
    </>
  );
};

export default Vision_2025;
