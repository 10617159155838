export default function HealthMinistry() {
  return (
    <div>
      <h3>Health Ministry</h3>
      <p>
        The Church believes its responsibility to make Christ known to the world includes a moral obligation to preserve human dignity by promoting optimal
        levels of physical, mental, and spiritual health. In addition to ministering to those who are ill, this responsibility extends to the prevention of
        disease through effective health education and leadership in promoting optimum health, free of tobacco, alcohol, other drugs, and unclean foods. Where
        possible, members shall be encouraged to follow a primarily vegetarian diet.
      </p>
      <h5>Link:</h5>
      <a href="http://www.adventistontario.org/Departments/HealthMinistries.aspx" rel="noopener noreferrer" target="_blank">
        http://www.adventistontario.org/Departments/HealthMinistries.aspx
      </a>
      <br />
      <br />
      <h5>Health Ministry Leader</h5>
      <p>Chuks Uwadiegwu</p>
      <a href="mailto:healthministry@torontonigeriansda.org">healthministry@torontonigeriansda.org</a>
    </div>
  );
}
