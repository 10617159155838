export const Newsletter = [
    {
        id: 1,
        name: "TNSDA Q1 2020 Newsletter",
        docSrc: 'https://drive.google.com/file/d/1UeQyBvFtVAkC0HBeHMwZDDIhCcLSCQHP/view?usp=sharing'
    },
    {
        id: 2,
        name: "TNSDA Q2 2020 Newsletter",
        docSrc: 'https://drive.google.com/file/d/104Bj6SLxuk4jGPEoNZOiG_iGG2_q5S9f/view?usp=sharing'
    },
    {
        id: 3,
        name: "TNSDA Q3 2020 Newsletter",
        docSrc: 'https://drive.google.com/file/d/1Ki_s4S4sIxw8jYRS3WCMGxmnDcErxFbg/view?usp=sharing'
    },
    {
        id: 4,
        name: "TNSDA Q4 2020 Newsletter",
        docSrc: 'https://drive.google.com/file/d/1yDpI9ajj-Ta1aVvbeozY3tlhi6hW8Aea/view?usp=sharing'
    },
    {
        id: 5,
        name: "TNSDA Q1 2021 Newsletter",
        docSrc: 'https://drive.google.com/file/d/1VKrRu1fInhSuEqmlIg-8KqEfmtv-lzuU/view?usp=sharing'
    },

]
