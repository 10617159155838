import React, { useState } from 'react';

import "../Assets/css/Gallery/Gallery.css"
import "../Assets/css/styles.css"

import img1 from '../Assets/images/Milvan-pictures/church-1.jpg';
import img2 from '../Assets/images/Milvan-pictures/church-2.jpg';
import img3 from '../Assets/images/Milvan-pictures/church-3.jpg';
import img4 from '../Assets/images/Milvan-pictures/church-4.jpg';
import img5 from '../Assets/images/Milvan-pictures/church-5.jpg';
import img6 from '../Assets/images/Milvan-pictures/church-6.jpg';


const PhotoGallery = () => {

    let data = [
        {
            id: 1,
            imgSrc: img1
        },
        {
            id: 2,
            imgSrc: img2
        },
        {
            id: 3,
            imgSrc: img3
        },
        {
            id: 4,
            imgSrc: img4
        },
        {
            id: 5,
            imgSrc: img5
        },
        {
            id: 6,
            imgSrc: img6
        }
]

const [modal, setModal] = useState(false);
const [tempImgSrc, setTempImgSrc] = useState('');

const getImg = (imgSrc) => {
  setTempImgSrc(imgSrc);
  setModal(true);
}

  return (
    <>
    <main className='main-photo-page'>
      <h2 className='green-underline'>Milvan Photos</h2>
      <section className={modal ? "modal open" : "modal"}>
        <img src={tempImgSrc} alt="full-image" />
        <i class="fa-solid fa-2x fa-x closeModal text-white" onClick={() => setModal(false)}></i>
      </section>
      <section className='gallery'>
        {
          data.map((item, index) => {
            return(
              <div className='pics' key={index} onClick={() => getImg(item.imgSrc)}>
                <img src={item.imgSrc} style={{width: "100%"}} alt="photos" />
              </div>
            )
          })
        }
      </section>
      </main>
    </>
  )
}

export default PhotoGallery
