export default function FamilyLifeMinistry() {
  return (
    <div>
      <h3>Family Life Ministry</h3>
      <p>
        The objective of family ministry is to strengthen marriage and the family. The family was established by divine creation with marriage at its center. As
        the primary setting in which values are learned and the capacity for close relationships with God and others is developed, its health is vital to the
        Church’s disciple-making mission.
      </p>
      <h5>Link:</h5>
      <a href="http://www.adventistontario.org/Departments/FamilyMinistries.aspx" rel="noopener noreferrer" target="_blank">
        http://www.adventistontario.org/Departments/FamilyMinistries.aspx
      </a>
      <br />
      <br />
      <h5>Family Life Ministry Leader</h5>
      <p>Pastor Joseph Amankwah</p>
      <a href="mailto:familylife@torontonigeriansda.org">familylife@torontonigeriansda.org</a>
    </div>
  );
}
