import React, { useState } from "react";
import axios from "axios";
import { Formik, ErrorMessage, Field } from "formik";
import { Alert } from "react-bootstrap";

//import 'bootstrap/dist/css/bootstrap.css'

import "../Assets/css/Button.css";
import "../Assets/css/Forms.css";
import formSchema from "../Components/Validation/formValidation";

function PrayerRequest() {
  const initialValues = {
    fullName: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    const data = {
      fullName: values.fullName,
      email: values.email,
      phone: values.phone,
      subject: values.subject,
      message: values.message,
    };

    const axiosConfig = {
      headers: {
        "Content-type": "application/json",
      },
    };
    try {
      await axios
        .post("https://tnsdac-backend.herokuapp.com/api/prayerRequests", data, axiosConfig)
        .then(() => {
          showAlert();
        })
        .then(resetForm({ values: "" }));
      //localStorage.setItem("prayerRequestData", JSON.stringify(data));
    } catch (error) {
      showError();
    }
  };

  const [errorMessage, showErrorMessage] = useState("none");
  const [display, setDisplay] = useState("none");

  function showAlert() {
    setDisplay("block");
  }

  function closeAlert() {
    setDisplay("none");
  }

  function showError() {
    showErrorMessage("block");
  }

  function closeError() {
    showErrorMessage("none");
  }

  return (
    <div className="form_style page-bg">
      <section>
        <div>
          <Alert variant="success" onClose={() => closeAlert()} style={{ display: display }} dismissible>
            Message sent successfully.
          </Alert>
          <Alert variant="danger" onClose={() => closeError()} style={{ display: errorMessage }} dismissible>
            Message not sent. Please try again.
          </Alert>
        </div>
        <div className="">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="request-form pt-4">
                <h1 className="text-center">Prayer Request</h1>
                <h4 className="text-center">ANYTIME. ANYWHERE.</h4>
                <p className="text-center">Someone is always available to pray with you.</p>
                <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={handleSubmit}>
                  {({ handleSubmit }) => (
                    <>
                      <form onSubmit={handleSubmit}>
                        <div className="form_contact_info">
                          <div className="form-group">
                            <label htmlFor="">Full Name: *</label>
                            <Field className="form-control" name="fullName" autoComplete="given-name" type="text" />
                            <p style={{ color: "red" }}>
                              <ErrorMessage name="fullName" />
                            </p>
                          </div>

                          <div className="form-group">
                            <label htmlFor="">Email: *</label>
                            <Field type="email" className="form-control" name="email" />
                            <p style={{ color: "red" }}>
                              <ErrorMessage name="email" />
                            </p>
                          </div>

                          <div className="form-group">
                            <label htmlFor="">Phone Number:</label>
                            <Field type="tel" className="form-control" name="phone" />
                            <p style={{ color: "red" }}>
                              <ErrorMessage name="phone" />
                            </p>
                          </div>
                        </div>

                        <div className="form-group">
                          <label htmlFor="">Subject: *</label>
                          <Field type="text" className="form-control" name="subject" />
                          <p style={{ color: "red" }}>
                            <ErrorMessage name="subject" />
                          </p>
                        </div>

                        <div className="form-group">
                          <label htmlFor="">Message: *</label>{" "}
                          <Field component="textarea" rows="60" className="form-control" name="message" type="text" placeholder="Enter Prayer Request" />
                          <p style={{ color: "red" }}>
                            <ErrorMessage name="message" />
                          </p>
                        </div>

                        <button type="submit" className="mainButton">
                          Send Message
                        </button>
                      </form>
                    </>
                  )}
                </Formik>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 mt-sm-4">
              <div className="prayer-request">
                <h5>24/7 Prayer Line:</h5>
                <p>
                  <a href="tel">+1 (647) 361-7264</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PrayerRequest;
