export default function Elders() {
  return (
    <>
      <h3>Elders</h3>
      <p>
        The Elders are recognized by the church as strong religious and
        spiritual leaders. In the absence of the Pastor, the Elder is the
        religious leader of the church and by precept and example must
        continually seek to lead the church into a deeper and fuller Christian
        experience.
      </p>
      <div>
        <h6>Head Elder and Chairman, Council of Elders</h6>
        <p>Elder Kunle Fasanu</p>
        
      </div>
      <h6>Serving Elders</h6>
      <p>Elder Daniel Ahunanya</p>
      <p>Elder Eugene Oseghale</p>
      <p>Elder Dele Babalola</p>
      <p>Elder Okezie Oluikpe</p>
      <a href="mailto:elders@torontonigeriansda.org">
        elders@torontonigeriansda.org
      </a>
    </>
  );
}
