export default function SabbathSchool() {
  return (
    <div>
      <h3>Sabbath School</h3>
      <p>
        The Sabbath School, the primary religious education program of the
        Church, has four purposes: study of the Scripture, fellowship, community
        outreach, and world mission emphasis.
      </p>
      <p>
        The General Conference Sabbath School and Personal Ministries Department
        distributes the Sabbath School Bible study guide for all age levels,
        provides designs for Sabbath School programming within the context of
        the various world division cultures, provides resources and training
        systems for Sabbath School teachers, and promotes world mission Sabbath
        School offerings.
      </p>
      <h5>Links:</h5>
      <a href="http://ssnet.org/study-guides/">Study guides</a>
      <br />
      <a href="http://ssnet.org/children-resources/">
        Children resources
      </a>
      <br />
      <a href="http://ssnet.org/juniors-teens/">
        Junior teens
      </a>
      <br />
      <a href="http://ssnet.org/youth-sabbath-school">
        Youth sabbath school
      </a>
      <br />
      <a href="http://ssnet.org/collegiate-young-adult">
        Collegiate young adult
      </a>
      <br /><br />
      <h5>Sabbath School Leader</h5>
      <p>Ngozi Oluikpe</p>
      <a href="mailto:sabbathschool@torontonigeriansda.org">
        sabbathschool@torontonigeriansda.org
      </a>
    </div>
  );
}
