import React, { useState } from "react";
import "../Assets/css/Ministry/Ministry.css";
import { Link } from "react-router-dom";



import Home from "../Components/Ministry/Home";
import PersonalMinistry from "../Components/Ministry/PersonalMinistry";
import Elders from "../Components/Ministry/Elders";
import SabbathSchool from "../Components/Ministry/SabbathSchool";
import Stewardship from "../Components/Ministry/Stewardship";
import CommunityService from "../Components/Ministry/CommunityService";
import ChildrenMinistry from "../Components/Ministry/ChildrenMinistry";
import Pathfinder from "../Components/Ministry/Pathfinder";
import YouthMinistry from "../Components/Ministry/YouthMinistry";
import HealthMinistry from "../Components/Ministry/HealthMinistry";
import FamilyLifeMinistry from "../Components/Ministry/FamilyLifeMinistry";
import PrayerMinistry from "../Components/Ministry/PrayerMinistry";
import Music from "../Components/Ministry/Music";
import WomenMinistry from "../Components/Ministry/WomenMinistry";
import Deacons from "../Components/Ministry/Deacons";
import Communications from "../Components/Ministry/Communications";
import AudioVisual from "../Components/Ministry/AudioVisual";

const Ministry = () => {
  const [width, setWidth] = useState("0%");

  // const [myText, setMyText] = useState(4);

  const [myText, setMyText] = useState(<Home />);

  // function newText() {
  //   setMyText(myText + 1);
  // }

  function openNav() {
    setWidth("250px");
  }

  function closeNav() {
    setWidth("0%");
  }

  function changeText() {
    setMyText("Changed");
  }

  return (
    <div className="main-page">
      <div id="mySidenav" className="sidenav" style={{ width: width }}>
        <Link
          to="#"
          className="closebtn"
          onClick={(e) => {
            closeNav();
          }}
        >
          &times;
        </Link>
        <Link
          to="#"
          onClick={(e) => {
            setMyText(<Elders />);
            closeNav();
          }}
          className="sidebar-links"
        >
          Elders
        </Link>
        <Link
          to="#"
          onClick={(e) => {
            setMyText(<PersonalMinistry />);
            closeNav();
          }}
        >
          Personal Ministries
        </Link>
        <Link
          to="#"
          onClick={(e) => {
            setMyText(<SabbathSchool />);
            closeNav();
          }}
        >
          Sabbath School
        </Link>
        <Link
          to="#"
          onClick={(e) => {
            setMyText(<Stewardship />);
            closeNav();
          }}
        >
          Stewardship
        </Link>
        <Link
          to="#"
          onClick={(e) => {
            setMyText(<ChildrenMinistry />);
            closeNav();
          }}
        >
          Children's Ministry
        </Link>
        <Link
          to="#"
          onClick={(e) => {
            setMyText(<Pathfinder />);
            closeNav();
          }}
        >
          Pathfinder Club
        </Link>
        <Link
          to="#"
          onClick={(e) => {
            setMyText(<YouthMinistry />);
            closeNav();
          }}
        >
          Youth Ministry
        </Link>
        <Link
          to="#"
          onClick={(e) => {
            setMyText(<CommunityService />);
            closeNav();
          }}
        >
          Community Service
        </Link>
        <Link
          to="#"
          onClick={(e) => {
            setMyText(<HealthMinistry />);
            closeNav();
          }}
        >
          Health Ministry
        </Link>
        <Link
          to="#"
          onClick={(e) => {
            setMyText(<FamilyLifeMinistry />);
            closeNav();
          }}
        >
          Family Life Ministry
        </Link>
        <Link
          to="#"
          onClick={(e) => {
            setMyText(<PrayerMinistry />);
            closeNav();
          }}
        >
          Prayer Ministry
        </Link>
        <Link
          to="#"
          onClick={(e) => {
            setMyText(<Music />);
            closeNav();
          }}
        >
          Music
        </Link>
        <Link
          to="#"
          onClick={(e) => {
            setMyText(<WomenMinistry />);
            closeNav();
          }}
        >
          Women's Ministry
        </Link>
        <Link
          to="#"
          onClick={(e) => {
            setMyText(<Deacons />);
            closeNav();
          }}
        >
          Deacons &amp; Deaconesses
        </Link>
        <Link
          to="#"
          onClick={(e) => {
            setMyText(<Communications />);
            closeNav();
          }}
        >
          Communications
        </Link>
        <Link
          to="#"
          onClick={(e) => {
            setMyText(<AudioVisual />);
            closeNav();
          }}
        >
          Audio-Visual
        </Link>
      </div>

      <div className="pt-3">
        <span onClick={openNav} className="px-3" style={{ cursor: "pointer" }}>
          <i className="fa-solid fa-2x fa-bars"></i> <span className="h6">Ministries and Officers</span>
        </span>
      </div>
      <div className="container" id="main" onClick={closeNav}>
        <p>{myText}</p>
        {/* <button onClick={changeText}>Change Text</button> */}
      </div>
    </div>
  );
};

export default Ministry;
