import React from 'react'

const Give = () => {
  return (
    <div>
      <h1 className='content'>Give</h1>
    </div>
  )
}

export default Give
