import * as Yup from 'yup'

const formSchema = Yup.object().shape({
    fullName: Yup.string()
        .min(3)
        .max(30)
        .required()
        .label("Full Name"),

    email: Yup.string()
        .email()
        .lowercase()
        .required()
        .label("Email"),

    phoneNumber: Yup.number()
        .max(15)
        .label("Phone Number"),

    subject: Yup.string()
        .min(3)
        .max(41)
        .required()
        .label("Subject"),

    message: Yup.string()
        .min(3)
        .required()
        .label("Message"),
})

export default formSchema
