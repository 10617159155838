import React from 'react';
import "../../../Assets/css/styles.css"

function AdminHome(props) {
  return (
      <>
        <div className='mx-3 col-4 col-lg-4 overview-card pt-3'>
          <span className='h6'>{props.Title}</span>
          <span style={{float: "right"}}><i className={props.Icon}></i></span><br />
          <p className='display-6'>{props.Number}</p>
        </div>
    </>
  )
}

export default AdminHome;
