import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../Assets/css/Navbar/Navbar.css";

// import bulletin from "../../bulletin/Bulletin-July-2-2022.pdf";

const Navbar = () => {
  const [width, setWidth] = useState("0%");

  function openNav() {
    setWidth("100%");
  }

  function closeNav() {
    setWidth("0%");
  }

  // Ministry dropdown
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const [dropdown, setDropdown] = useState(false);
  const handleDropdown = () => setDropdown(!dropdown);

  // Media dropdown
  const [mediaClick, setMediaClick] = useState(false);
  const handleMediaClick = () => setMediaClick(!mediaClick);

  const [mediaDropdown, setMediaDropdown] = useState(false);
  const handleMediaDropdown = () => setMediaDropdown(!mediaDropdown);

  return (
    <>
      <nav className="main-nav">
        <Link to="/" className="navbar-logo">
          <span className="church-logo">
            <img className="logo-img" src="/images/adventist-symbol--white.png" alt="logo" />
            <span className="logo-text">Toronto Nigerian Seventh-Day Adventist Church</span>
          </span>
        </Link>

        <ul className="links mr-3">
          <li>
            <Link to="/about">About</Link>
          </li>
          <li className="ministry">
            <Link to="/ministry">Ministry</Link>
            {/* <i className="caret fa-solid fa-caret-down"></i> */}
            {/* <ul className='ministry-links'>
              <li><Link to='/prayer-request' className='ministry-sublink'>Prayer Request</Link></li>
              <li><Link to='/bible-study-request' className='ministry-sublink'>Bible Study Request</Link></li>
              <li><Link to='/visit-request' className='ministry-sublink'> Visit Request</Link></li>
            </ul> */}
          </li>
          <li>
            <Link to="/events">Events</Link>
          </li>
          <li className="media">
            Media<i className="caret fa-solid fa-caret-down"></i>
            <ul className="media-links">
              <li>
                <Link to="/photos">Photos</Link>
              </li>
              <li>
                <a href="/images/bulletin_june_22.pdf" rel="noopener noreferrer" target="_blank">
                  Bulletin
                </a>
              </li>
              <li>
                <Link to="/events" onClick={closeNav}>
                  Newsletter
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/vision_2025">Vision 2025</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://adventistgiving.org/#/org/AN6MGF/envelope/start" className="give">
              Give
            </a>
          </li>
        </ul>

        <div className="hamburger pt-3">
          <Link to="#" className="text-white" onClick={openNav}>
            <i class="fa-solid fa-2x fa-bars"></i>
          </Link>
        </div>
      </nav>

      <div id="sideNav" className="sideNav" style={{ width: width }}>
        <Link to="" className="closebtn" onClick={closeNav}>
          &times;
        </Link>
        <Link to="/about" onClick={closeNav}>
          About
        </Link>
        <Link to="/ministry" onClick={closeNav} className="text-grey">
          Ministry
          {/* <i
            className={
              click
                ? "caret fa-solid fa-caret-up"
                : "caret fa-solid fa-caret-down"
            }
            onClick={handleDropdown}
          ></i> */}
        </Link>
        {/* <div className={dropdown ? "openDropdown" : "closeDropdown"}>
          <Link to="/prayer-request" onClick={closeNav}>
            Prayer Request
          </Link>
          <Link to="/bible-study-request" onClick={closeNav}>
            Bible Study Request
          </Link>
          <Link to="">Visit Request</Link>
        </div> */}
        <Link to="/events" onClick={closeNav}>
          Events
        </Link>
        <a onClick={handleMediaClick} className="text-grey">
          Media
          <i className={mediaClick ? "caret fa-solid fa-caret-up" : "caret fa-solid fa-caret-down"} onClick={handleMediaDropdown}></i>
        </a>
        <div className={mediaDropdown ? "openDropdown" : "closeDropdown"}>
          <Link to="/photos" onClick={closeNav}>
            Photos
          </Link>
          <a href="/images/bulletin_june_22.pdf" rel="noopener noreferrer" target="_blank" onClick={closeNav}>
            Bulletin
          </a>
          <Link to="/events" onClick={closeNav}>
            Newsletter
          </Link>
        </div>
        <Link to="/vision_2025" onClick={closeNav}>
          Vision 2025
        </Link>
        <Link to="/contact" onClick={closeNav}>
          Contact
        </Link>
        <a target="_blank" rel="noopener noreferrer" href="https://adventistgiving.org/#/org/AN6MGF/envelope/start" className="give">
          Give
        </a>
      </div>
    </>
  );
};

export default Navbar;
