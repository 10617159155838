export default function Pathfinder() {
  return (
    <div>
      <h3>Pathfinder Club</h3>
      <p>
        The club is part of a world-wide co-ed scouting type organization sponsored by the Seventh-day Adventist Church. Junior and teens in grades 5-12 of any
        religion, or none at all, are welcomed and encouraged to join this ministry
      </p>

      <br />
      <h5>Pathfinder Leader</h5>
      <p>Timothy Fagbenro</p>
      <a href="mailto:pathfinders@torontonigeriansda.org">pathfinders@torontonigeriansda.org</a>
    </div>
  );
}
