import OfficerTable from "../Table/OfficerTable";

export default function Home() {
  return (
    <div>
      <h3>Ministry</h3>
      <p>
        As a church, we are called to be ministers and to serve in different capacities. We are an organized church with members in different departments,
        taking responsibilities to promote the aims of the Church. We use our God-given talents to do the best we can to serve God and Humanity.
      </p>
      <h3>Officers</h3>
      <OfficerTable className="col" />
    </div>
  );
}
