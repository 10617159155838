import React from "react";

const AgendaCalendar = () => {
  return (
    <div className="container">
      <div className="text-center">
        <h3 className="worship-with-us mt-4">Calendar</h3>
      </div>

      <div className="gmap_canvas">
        <iframe
          src='https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FToronto&mode=AGENDA&showNav=1&showTz=1&showCalendars=0&showTabs=0&showPrint=0&showTitle=0&showDate=0&src=dG5zZGFjaHVyY2hAZ21haWwuY29t&src=dnEydGRlMHBzcHJvcXUyc2tmcm02anM2aTRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&src=ZW4uY2FuYWRpYW4jaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23039BE5&color=%23D50000&color=%2333B679&color=%230B8043" style="border-width:0" width="800" height="600" frameborder="0" scrolling="no"'
          title="calendar"
          style={{
            border: "solid 1px rgba(0,0, 0, 0.2)",
            display: "block",
            margin: "0 auto",
            padding: "10px",
          }}
          width="90%"
          height="400px"
          frameborder="0"
          scrolling="no"
        ></iframe>
      </div>
    </div>
  );
};

export default AgendaCalendar;
