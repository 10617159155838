export default function PrayerMinistry() {
  return (
    <div>
      <h3>Prayer Ministry</h3>
      <p>
        The Prayer Ministries department is there to spiritually strengthen and support every ministry, pastor, and administrator at every level of the church
        by fusing the three fundamental elements of mission—prayer, Bible study, and ministry.
      </p>

      <br />
      <h5>Prayer Ministry Leader</h5>
      <p>Grace Evo</p>
      <a href="mailto:prayerministry@torontonigeriansda.org">prayerministry@torontonigeriansda.org</a>
    </div>
  );
}
