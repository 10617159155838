export default function PersonalMinistry() {
  return (
    <>
      <h3>Personal Ministry</h3>
      <p>
        The Personal Ministries Department provides resources and trains church
        members to unite their efforts with the Pastor and Church Officers in
        sharing the gospel of salvation through Christ. The aim of the
        department is to enlist every member in active soul-wining service for
        God.
      </p>
      <div>
        <h6>Personal Ministry Leader</h6>
        <p>Elder Eugene Oseghale</p>
      </div>
      <h6>Personal Ministry Secretary</h6>
      <p> Mege Ebifegha</p>
        <a href="mailto:personalministries@torontonigeriansda.org">
          personalministries@torontonigeriansda.org
        </a>
    </>
  );
}
