export default function Music() {
  return (
    <div>
      <h3>Music</h3>
      <p>
        Music plays a large part in the life of the church. This may include worship leaders, a choir, children’s choir, Praise team, etc. Along with these are
        the many individuals who make a contribution to our worship services by playing piano, organ and other instruments, as well as vocal soloists.
      </p>

      <br />
      <h5>Music Leader</h5>
      <p>Obinwa Onyemachi</p>
      <a href="mailto:music@torontonigeriansda.org">music@torontonigeriansda.org</a>
    </div>
  );
}
