import React, { useEffect } from "react";

// STYLES
import "./Assets/css/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";

// AOS transition library
import Aos from "aos";
import "aos/dist/aos.css";

// ROUTES
import { Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Ministry from "./Pages/Ministry";
import Media from "./Pages/Media";
import Contact from "./Pages/Contact";
import Give from "./Pages/Give";
import PrayerRequest from "./Pages/PrayerRequest";
import BibleStudyRequest from "./Pages/BibleStudyRequest";
import Footer from "./Components/Footer/Footer";
import Events from "./Pages/Events";
import PhotoGallery from "./Pages/PhotoGallery";
import Admin from "./Pages/Admin/AdminLogin";
import AdminHome from "./Pages/Admin/AdminHome";
import Vision2025 from "./Pages/Vision_2025";
import Archive from "./Pages/Archive";
import PageNotFound from "./Pages/PageNotFound";
import TermsOfUse from "./Pages/TermsOfUse";
import PrivacyStatement from "./Pages/PrivacyStatement";
import ScrollToTop from "./Components/ScrollToTop";
import GoogleAnalytics from "./Components/Admin/GoogleAnalytics";

function App() {
  GoogleAnalytics();
  Aos.init(); // Adds transitions
  ScrollToTop();
  return (
    <div>
      <Navbar />
      <Routes>
        {/* <ScrollToTop /> */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/ministry" element={<Ministry />} />
        <Route exact path="/events" element={<Events />} />
        <Route exact path="/media" element={<Media />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/give" element={<Give />} />
        <Route exact path="/prayer-request" element={<PrayerRequest />} />
        <Route exact path="/bible-study-request" element={<BibleStudyRequest />} />
        <Route exact path="/photos" element={<PhotoGallery />} />
        <Route exact path="/admin" element={<Admin />} />
        <Route exact path="/admin/home" element={<AdminHome />} />
        <Route exact path="/vision_2025" element={<Vision2025 />} />
        <Route exact path="/archive" element={<Archive />} />
        <Route exact path="/privacy-statement" element={<PrivacyStatement />} />
        <Route exact path="/terms-of-use" element={<TermsOfUse />} />
        <Route exact path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
