export default function Communications() {
  return (
    <div>
      <h3>Communications</h3>
      <p>
        Communication is key for the proclamation of the gospel to this dying world. The Communication team therefore employ various tools to spread the good
        news of salvation. While each member of our church is a witness, the Communication team works with all leaders and departments in the Church to
        strengthen and propel the gospel commission so that all God's people will come to a knowledge of truth.
      </p>

      <br />
      <h5>Communications Leader</h5>
      <p>Diran Akinrinade</p>
      <a href="mailto:communications@torontonigeriansda.org">communications@torontonigeriansda.org</a>
    </div>
  );
}
